import {Router} from 'symfony-ts-router';
const routes = require('./../../../public/js/fos_js_routes.json');

const router = new Router();

// @ts-ignore
router.setRoutingData(routes);

export default router;

const CART = {
    ADD_ITEM: 'app.ajax.cart.add_item',
    REMOVE_ITEM: 'app.ajax.cart.remove_item',
    UPDATE_CART_ITEM_QUANTITY: 'app.ajax.cart.update_quantity',
    CONFIRM_CART: 'app.ajax.cart.confirm',
};

const HISTORY = {
    ORDERS_TABLE: 'app.ajax.history.orders',
    TRANSACTIONS_TABLE: 'app.ajax.history.transactions'
};

const SECURITY = {
    ADMIN_LOGIN_CHECK: 'admin.login_check',
    USER_LOGIN_CHECK: 'app.login_check'
};


export const API_ROUTES = {
    CART: {...CART},
    HISTORY: {...HISTORY},
    SECURITY: {...SECURITY}
};