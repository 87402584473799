"use strict";

import {UtilServicesHelper} from "UtilServices";
import './theme-iren-condomini.scss'

class ThemeIrenCondomini {


    init = async (): Promise<void> =>
    {
        //UtilServicesHelper.addBlobs(['primary', 'secondary', 'tertiary']);
        UtilServicesHelper.stickyNav();
    }

}

export default ThemeIrenCondomini;

let themeIrenCondomini = new ThemeIrenCondomini();
themeIrenCondomini.init().then();